import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b852cd8 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _1ac3e7f4 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _4abe8908 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _2769b9b0 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _31749e71 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _7985080a = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _774afdff = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _decb1d24 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _5426a342 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _6f28ce18 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _191220ba = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _8f58001e = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _45e49bb2 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _bc614396 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _f746967a = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _108c538f = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _a1463ca8 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _6daf1d6e = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _0a01f04b = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _1d96078c = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _0bbb84ca = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _2b852cd8,
    name: "kits"
  }, {
    path: "/lojas",
    component: _1ac3e7f4,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _4abe8908,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _2769b9b0,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _31749e71,
    name: "motos"
  }, {
    path: "/reset",
    component: _7985080a,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _774afdff,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _decb1d24,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _5426a342,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _6f28ce18,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _191220ba,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _8f58001e,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _45e49bb2,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _bc614396,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _f746967a,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _108c538f,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _a1463ca8,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _6daf1d6e,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _0a01f04b,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _1d96078c,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _0bbb84ca,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
